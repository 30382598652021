import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Tooltip ,
  NavItem,
  TabPane,
  NavLink,
  Nav,
  TabContent,
  Container,
  Row,
  Col,
} from "reactstrap";
import GS_DataCollection from "./GS_DataCollection";
import GS_ProcessPipelines from "./GS_ProcessPipelines";

function GettingStarted() {
  
  const [tabId, setTabId] = useState("1");
  const whichTabIsActive = [true, false,];
  const [tabActive, setTabActive] = useState(whichTabIsActive);
  const activeTab = (id) => {
    setTabId(id);
    var newtTabActive = [false,false, false, false,false];
    newtTabActive[id-1] = true;
    setTabActive(newtTabActive);
  };
  console.log("tab id",tabId)
  return (
    <div>
<Nav tabs style={{border:'none',marginBottom:0}}>
<NavItem>
                  <NavLink
                    className={tabActive[1] ? "active" : ""}
                    onClick={() => activeTab("2")}
                    href="#"
                    style={{ color: tabActive[1]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none' }}
                  >
                    <h6 className="dashboard-nav-tabs">&nbsp; &nbsp;Open Campaigns&nbsp;</h6>
                  </NavLink>
                </NavItem>
              <NavItem>
                  <NavLink
                    className={tabActive[0] ? "active" : ""}
                    onClick={() => activeTab("1")}
                    href="#"
                    style={{ color: tabActive[0]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none'  }}
                  >
                    <h6 className="dashboard-nav-tabs">&nbsp; &nbsp;Targeted&nbsp;Campaigns</h6>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent style={{ paddingTop: 20 }} activeTab={tabId}>
              <TabPane tabId="1" style={{ marginBottom: 100 }}>
                <GS_DataCollection/>
              </TabPane>
              <TabPane tabId="2" style={{ marginBottom: 100 }}>
                <GS_ProcessPipelines/>
              </TabPane>
              </TabContent>

    </div>
  )
}

export default GettingStarted